@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@uppy/core/dist/style.css';
@import '@uppy/dashboard/dist/style.css';
@import '@uppy/file-input/dist/style.css';

@layer base {
    select {
        appearance: none !important;
    }
}

.uppy-FileInput-container {
    margin-bottom: 0;
}
.uppy-FileInput-btn {
    border: 1px solid #19A2A8;
    color: #19A2A8;
}
.uppy-FileInput-btn:hover {
    background-color: transparent;
    color: #19A2A8;
}
.uppy-StatusBar {
    min-width: 200px;
}

.btn-outline {
    border: 1px solid #19A2A8;
    color: #19A2A8;
}

option[disabled]:first-child {
    color: gray;
}